import IconArrowDown from '@/assets/icons/icon-arrow-down.svg?react';
import mobileAppImage from '@/assets/images/mobile-overview.png';
import sliderFolders from '@/assets/images/slider-folder.png';
import sliderReading from '@/assets/images/slider-reading.png';
import sliderScan from '@/assets/images/slider-scan.png';
import sliderUpload from '@/assets/images/slider-upload.png';
import { Button } from '@/components/ui/button';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

const features = [
  {
    id: 0,
    title: 'Easy to use.',
    image: mobileAppImage,
    alt: 'Easy to use view',
  },
  {
    id: 1,
    title: 'Scan Papers.',
    image: sliderScan,
    alt: 'Scan papers view',
  },
  {
    id: 2,
    title: 'Upload Documents.',
    image: sliderUpload,
    alt: 'Upload documents view',
  },
  {
    id: 3,
    title: 'Reading View.',
    image: sliderReading,
    alt: 'Reading view',
  },
  {
    id: 4,
    title: 'Folders.',
    image: sliderFolders,
    alt: 'Folders view',
  },
];

const AppSlider = () => {
  const sectionRef = useRef<HTMLElement>(null);
  const tryButtonRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const progressHeight = 100 / features.length;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  /**
   * Determine slider text & nav visibility
   * (You may want to refine or remove these checks if
   *  they're still causing layout shifts at extreme zoom.)
   */
  useEffect(() => {
    const handleScroll = () => {
      if (!tryButtonRef.current || !sectionRef.current) return;

      const buttonRect = tryButtonRef.current.getBoundingClientRect();
      const sectionRect = sectionRef.current.getBoundingClientRect();

      // Slider text visibility
      const isSliderVis =
        sectionRect.top < window.innerHeight * 0.8 &&
        sectionRect.bottom > 0 &&
        (window.innerWidth >= 768 ||
          (buttonRect.top > window.innerHeight * 0.8 &&
            sectionRect.top < window.innerHeight * 0.4));

      // Navigation buttons visibility
      const isNavVis =
        sectionRect.top < window.innerHeight * 0.6 &&
        buttonRect.top > window.innerHeight * 0.6 &&
        (window.innerWidth >= 768 ||
          sectionRect.top < window.innerHeight * 0.4);

      setIsSliderVisible(isSliderVis);
      setIsNavVisible(isNavVis);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Click a slide => scroll that slide into view
  const handleSlideClick = (index: number) => {
    setActiveIndex(index);
    const slide = document.querySelector(`[data-index="${String(index)}"]`);
    if (slide) {
      slide.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  // Track which slide is nearest the viewport center
  useEffect(() => {
    const handleScroll = () => {
      const slides = document.querySelectorAll('[data-index]');
      const viewportMiddle = window.innerHeight * 0.5;
      let closestSlide = { index: 0, distance: Infinity };

      slides.forEach((slide, index) => {
        const rect = slide.getBoundingClientRect();
        const slideMiddle = rect.top + rect.height / 2;
        const distance = Math.abs(slideMiddle - viewportMiddle);

        if (distance < closestSlide.distance) {
          closestSlide = { index, distance };
        }
      });

      // If the closest slide is reasonably close to center, update activeIndex
      if (closestSlide.distance < window.innerHeight * 0.4) {
        setActiveIndex(closestSlide.index);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to first slide
  const scrollToTop = () => {
    setActiveIndex(0);
    const firstSlide = document.querySelector('[data-index="0"]');
    if (firstSlide) {
      firstSlide.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  // Scroll to last slide
  const scrollToBottom = () => {
    setActiveIndex(features.length - 1);
    const lastSlide = document.querySelector(
      `[data-index="${String(features.length - 1)}"]`,
    );
    if (lastSlide) {
      lastSlide.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <section
      ref={sectionRef}
      className="relative min-h-dvh bg-white"
      aria-label="App Features Slider"
    >
      <div className="top-0 z-10 bg-white px-4 pt-12 sm:px-6">
        <div className="text-center">
          <h2 className="text-4xl font-bold md:text-5xl" id="slider-heading">
            How the app works
          </h2>
        </div>
      </div>

      <div className="relative mx-auto max-w-[1300px] px-4 pt-[60px]">
        <div className="relative grid grid-cols-1 gap-8 md:grid-cols-[1.5fr_auto_0.8fr] md:gap-12">
          {/* Interactive text buttons */}
          <div
            role="tablist"
            aria-label="Feature navigation"
            className={`
              fixed inset-x-0 bottom-8 z-50 order-2 transition-opacity duration-300 md:relative md:bottom-auto
              md:order-1 [&::-webkit-scrollbar]:hidden
              ${isSliderVisible ? 'opacity-100' : 'pointer-events-none opacity-0'}
            `}
          >
            <div className="relative flex md:sticky md:top-[25vh] md:flex-col md:items-end md:space-y-6 lg:space-y-8">
              {/* Mobile version */}
              <div className="relative w-full overflow-visible md:hidden">
                <div
                  className="flex items-center"
                  style={{
                    transform: `translateX(calc(50vw - ${String(
                      activeIndex * 100,
                    )}vw - 50%))`,
                    transition: 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
                  }}
                >
                  {features.map((feature, index) => (
                    <div
                      key={feature.id}
                      className="flex w-screen flex-shrink-0 justify-center"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          handleSlideClick(index);
                        }}
                        className={`
                          rounded-full px-6 py-2 text-center text-xl font-bold transition-all duration-300 
                          ${
                            index === activeIndex
                              ? 'scale-110 bg-white font-black text-blue-600 shadow-md'
                              : 'scale-90 text-gray-400'
                          }
                        `}
                      >
                        {feature.title}
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Desktop version */}
              <div className="hidden whitespace-nowrap md:flex md:flex-col md:items-end md:space-y-6 lg:space-y-8">
                {features.map((feature, index) => (
                  <button
                    type="button"
                    key={feature.id}
                    role="tab"
                    aria-selected={index === activeIndex}
                    aria-controls={`slide-${String(feature.id)}`}
                    onClick={() => {
                      handleSlideClick(index);
                    }}
                    className={`
                      text-nowrap text-right text-2xl font-bold transition-all duration-300 hover:text-blue-600 lg:text-3xl 
                      xl:text-4xl
                      ${
                        index === activeIndex
                          ? 'font-black text-black'
                          : 'text-gray-400'
                      }
                    `}
                  >
                    {feature.title}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Slider content */}
          <div
            className="relative order-1 md:order-2"
            role="tabpanel"
            aria-labelledby="slider-heading"
          >
            <div className="sticky top-[10vh] flex justify-center">
              <div
                className="xs:w-[260px] xs:[height:min(420px,65vh)] w-[220px] overflow-hidden 
                rounded-2xl [height:min(380px,60vh)]
                sm:w-[280px] sm:[height:min(500px,70vh)]
                md:w-[300px] md:rounded-3xl md:[height:min(600px,75vh)]
                lg:w-[320px] lg:[height:min(650px,80vh)]
                xl:w-[340px] xl:[height:min(680px,85vh)]"
              >
                {features.map((feature, index) => (
                  <div
                    key={feature.id}
                    id={`slide-${String(feature.id)}`}
                    role="tabpanel"
                    aria-hidden={index !== activeIndex}
                    className={`absolute inset-0 transition-all duration-500 ease-in-out ${
                      index === activeIndex
                        ? 'translate-y-0 opacity-100'
                        : index < activeIndex
                          ? '-translate-y-8 opacity-0'
                          : 'translate-y-8 opacity-0'
                    }`}
                  >
                    <div className="size-full rounded-2xl bg-white md:rounded-3xl">
                      <img
                        src={feature.image.src}
                        alt={feature.alt}
                        className="size-full object-contain p-2"
                        loading="lazy"
                        width={380}
                        height={800}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Invisible scroll markers */}
            {features.map((feature) => (
              <div
                key={feature.id}
                data-index={feature.id}
                className="h-[75vh] snap-start snap-always"
              />
            ))}
          </div>

          {/* Progress indicator */}
          <div className="relative order-3 hidden md:block">
            <div className="sticky top-[25vh] flex h-[300px] items-center">
              <div className="relative h-full w-1.5">
                <div className="absolute inset-0 rounded-full bg-gray-200">
                  <motion.div
                    className="absolute w-full rounded-full bg-blue-600"
                    animate={{
                      height: `${String(progressHeight)}%`,
                      top: `${String(activeIndex * progressHeight)}%`,
                    }}
                    transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Buttons */}
        <div
          role="navigation"
          aria-label="Slider navigation"
          className={`
            fixed inset-x-0 bottom-24 z-50 flex flex-row
            justify-center gap-4 transition-opacity duration-300
            lg:bottom-auto lg:left-auto lg:right-8 lg:top-1/2 lg:-translate-y-1/2 lg:transform lg:flex-col lg:items-center lg:justify-center
            ${!isMounted ? 'opacity-0' : isNavVisible ? 'opacity-100' : 'pointer-events-none opacity-0'}
          `}
        >
          <button
            type="button"
            onClick={scrollToTop}
            aria-label="Go to first slide"
            className="rounded-full bg-white p-3 shadow-lg transition-colors hover:bg-gray-50"
          >
            <IconArrowDown className="size-6 rotate-180" />
          </button>
          <button
            type="button"
            onClick={scrollToBottom}
            aria-label="Go to last slide"
            className="rounded-full bg-white p-3 shadow-lg transition-colors hover:bg-gray-50"
          >
            <IconArrowDown className="size-6" />
          </button>
        </div>
      </div>

      {/* Try Now Button */}
      <div
        ref={tryButtonRef}
        className="z-[100] mt-20 flex w-full justify-center pb-20"
      >
        <Button
          asChild
          variant="ghost"
          aria-label="Try app for free"
          className="relative rounded-full bg-[#102087] px-6 py-4 text-base font-medium text-white transition-all duration-300 hover:-translate-y-1 hover:shadow-lg sm:px-24 sm:py-8 sm:text-lg"
        >
          <a href="/app/continue" target="_blank" rel="noopener noreferrer">
            Try Now for Free
          </a>
        </Button>
      </div>
    </section>
  );
};

export default AppSlider;
